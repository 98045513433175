import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Webdevelop = () => {
  const { handleOpen } = React.useContext(CustomerContext);

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings10 = {
    className: "center-slider",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings11 = {
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3.5,
    autoplay: true,
    speed: 500,

    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/web-development-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Seo
        title="
        Website Development Company in Bangalore | Hyderabad
        "
        description="Website development company in bangalore and hyderabad.  Explore our Web Development services to see how we craft innovative, user-friendly websites that represent your brand seamlessly online."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="website development company in bangalore,
        website development company in hyderabad,
        website development services in bangalore,
        website development services in hyderabad,
        top website development company in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in//web-development-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/web-development/web-banner.png"
            alt="G-Rank"
            title="Web Development"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/web-development/web-banner-mobile.png"
            alt="G-Rank"
            title="Web Development"
          />

          <div className="web-develop-banner-div">
            <h1 className="waviy2">
              <span>W</span>
              <span>e</span>
              <span>b</span>
              <span
                style={{ color: "transparent", width: "15px", content: "" }}
              ></span>

              <span>D</span>
              <span>e</span>
              <span>v</span>
              <span>e</span>
              <span>l</span>
              <span>o</span>
              <span>p</span>
              <span>m</span>
              <span>e</span>
              <span>n</span>
              <span>t</span>
              <br />
              <span>A</span>
              <span>g</span>
              <span>e</span>
              <span>n</span>
              <span>c</span>
              <span>y</span>
            </h1>
            <h2>
              Drive excellence through
              <br />
              creativity​
            </h2>
            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">GET STARTED</NavLink>
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#fff">Web Development</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* section2 Start */}
        <section className="web-section2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <img
                  className="img-fluid w-100"
                  src="assest/photos/web-development/web-section2-img2.png"
                  alt="G-Rank"
                  title="Web Development"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <motion.h4
                      className="section2-web-develop-header"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      Websites that Speaks Your <br />
                      Brand's Language
                    </motion.h4>
                    <p className="section2-web-develop-passage">
                      Our brand language is creating visually appealing,
                      user-friendly websites that make a strong impression,
                      drive business growth, and effectively convey your brand
                      identity to the target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section2 end */}
        {/* blue text start */}
        <section className="web-dev-blue-section">
          <div className="container">
            <div className="row ">
              <div className="col-12 web-dev-blue-div">
                <div className="row">
                  <div className="col-12">
                    <motion.h4
                      className="web-dev-blue-heading"
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1 }}
                    >
                      why choose us ?
                    </motion.h4>
                  </div>
                  <motion.div
                    className="col-12 col-md-4 col-lg-4 web-blue-div1"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <h4>Attention to Detail</h4>
                    <p>
                      Our team pays close attention to the finer details of
                      website development. This ensures that the final product
                      is polished and meets the clients' specific requirements.
                    </p>
                  </motion.div>
                  <motion.div
                    className="col-12 col-md-4 col-lg-4 web-blue-div-white"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <h4>
                      Knowledge of Latest
                      <br /> Industry Trends
                    </h4>
                    <p>
                      We stay up-to-date with the latest industry trends to
                      ensure their clients' websites are modern and relevant.
                    </p>
                  </motion.div>
                  <motion.div
                    className="col-12 col-md-4 col-lg-4 web-blue-div2"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1.2 }}
                  >
                    <h4>Personalized Approach</h4>
                    <p>
                      We take a personalized approach to each client's project.
                      Our goal is to tailor their services to meet the unique
                      needs and goals of individual businesses.
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* blue text end  */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* web-sliider section start */}

        <div className="container-fluid slider-icon-div">
          <div className="row ">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                our process
              </motion.h4>
            </div>
            <div className="col-12 web-slider-div">
              <Slider {...settings11}>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon5.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>
                      Social Media <br />
                      Integration
                    </h4>
                    <p>
                      Integrate social media sharing buttons and feeds.
                      <br />
                      <br />
                      Optimize content for easy sharing on social platforms.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon2.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>Responsive Design</h4>
                    <p>
                      Develop a responsive design to ensure optimal user
                      experience on all devices.
                      <br />
                      <br />
                      Prioritize mobile responsiveness for mobile-first
                      indexing.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon3.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>
                      User Experience
                      <br /> (UX) Design
                    </h4>
                    <p>
                      Design intuitive navigation and user interfaces to enhance
                      engagement.
                      <br />
                      <br />
                      Create clear pathways to conversion points.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon4.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>
                      Conversion Rate <br />
                      Optimization
                      <br /> (CRO)
                    </h4>
                    <p>
                      Collaborate with SEO experts to ensure on-page
                      optimization.
                      <br />
                      <br />
                      Plan URL structures, meta tags, and site architecture for
                      SEO.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon1.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>SEO Integration</h4>
                    <p>
                      Collaborate with SEO experts to ensure on-page
                      optimization.
                      <br />
                      <br />
                      Plan URL structures, meta tags, and site architecture for
                      SEO.
                    </p>
                  </div>
                </div>

                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon6.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>
                      Content <br />
                      Integration
                    </h4>
                    <p>
                      Collaborate with content creators to ensure high-quality,
                      SEO-friendly content.
                      <br />
                      <br />
                      Incorporate content that aligns with digital marketing
                      messaging.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider slider-margin-top">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon7.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>
                      Security and <br />
                      Compliance
                    </h4>
                    <p>
                      Implement security measures (SSL certificates, data
                      encryption) for user trust.
                      <br />
                      <br />
                      Ensure compliance with data privacy regulations (e.g.,
                      GDPR).
                    </p>
                  </div>
                </div>
                <div>
                  <div className="web-text-slider ">
                    <img
                      className="img-fluid web-text-slider-img"
                      src="assest/photos/web-development/web-dev-icon7.png"
                      alt="G-Rank"
                      title="Web Development"
                    />
                    <h4>SEO Integration</h4>
                    <p>
                      Collaborate with SEO experts to ensure on-page
                      optimization.
                      <br />
                      <br />
                      Plan URL structures, meta tags, and site architecture for
                      SEO.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        {/* web-sliider section end  */}
        {/* icon slider start*/}
        <div className="container-fluid slider-icon-div">
          <div className="row ">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Explore our tool box
              </motion.h4>
            </div>

            <div className="col-12">
              <Slider {...settings10}>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50  "
                    src="assest/photos/web-development/web-dev-logo1.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo2.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo3.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo4.png"
                    alt="G-Rank"
                    title="grank"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo5.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo6.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid w-50 "
                    src="assest/photos/web-development/web-dev-logo7.png"
                    alt="G-Rank"
                    title="Web Development"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* icon slider end*/}
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQs & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          Why PHP is important in web development?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          PHP (Hypertext Preprocessor) is a server-side
                          scripting language commonly used for web development.
                          It is essential for creating dynamic and interactive
                          websites, handling form data, and interacting with
                          databases, making it a fundamental tool for building
                          robust and feature-rich web applications.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Why should I choose WordPress for my Business?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          WordPress offers easy-to-use content management, a
                          vast array of customizable themes and plugins, and
                          excellent SEO capabilities, making it an ideal
                          platform for creating dynamic, user-friendly websites
                          without extensive technical expertise.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          What are the benefits of custom WordPress development?
                          <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Custom WordPress development allows for tailored
                          designs, unique features, and seamless integration of
                          specific functionalities, ensuring that your website
                          reflects your brand identity and meets your business
                          requirements with precision.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          What e-commerce platform is best for scaling my online
                          store?
                          <span>
                            {open4 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Platforms like WooCommerce and Shopify provide robust
                          e-commerce solutions with scalability, secure payment
                          gateways, and advanced inventory management features,
                          enabling seamless expansion and optimization of your
                          online store as your business grows.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          How can I optimize my e-commerce website for
                          conversions and user experience?
                          <span>
                            {open5 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Optimizing your e-commerce website involves
                          implementing intuitive navigation, clear product
                          descriptions, streamlined checkout processes, and
                          responsive design to enhance user experience and drive
                          higher conversion rates, ultimately boosting sales and
                          customer satisfaction.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* action yellow start*/}
        <div className="web-dev-action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Let’s Work Together
            <span className="d-block">
              Let’s find out how to work together and create something
              meaningful or valuable.
            </span>
          </motion.p>
          <NavLink to="">
            <motion.p
              className="web-dev-action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Get Started
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
      </section>
    </>
  );
};

export default Webdevelop;
