import { API, endpoints } from "../api";

class AddtocartrediffService {
  static createAddtocartrediff() {
    return API.post(endpoints.api.radiffmail.create);
  }

  static updateAddtocartrediff() {
    return API.post(endpoints.api.radiffmail.update);
  }

  static deleteAddtocartrediff() {
    return API.post(endpoints.api.radiffmail.delete);
  }

  static fetchOneAddtocartrediff() {
    return API.post(endpoints.api.radiffmail.getOne);
  }

  static fetchAllAddtocartrediff() {
    return API.post(endpoints.api.radiffmail.getAll);
  }

  static fetchAllAddtocartrediff(query) {
    const url = query
      ? endpoints.api.radiffmail.getAll + query
      : endpoints.api.radiffmail.getAll;
    return API.get(url);
  }
}

export default AddtocartrediffService;
