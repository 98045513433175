import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Seo from "../seo/Seo";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const SearchEngineMarketing = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };

    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/search-engine-marketing-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Seo
        title="PPC Services Company in Bangalore | Hyderabad"
        description="PPC Services agency in Bangalore and Hyderabad. Explore the art of paid advertising and keyword optimization that ensures your brand's visibility and success. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="ppc services in bangalore,
        ppc services in hyderabad,
        top ppc services agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/search-engine-marketing-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        <div className="performance-banner">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/performance-marketing/performance-sem-banner.png"
            alt="G-Rank"
            title="performance banner"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/performance-marketing/performance-sem-banner-mobile.png"
            alt="G-Rank"
            title="performance banner"
          />
          <div className="performance-sem-banner-div">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Search Engine
              <br />
              Marketing Agency
            </motion.h1>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/performance-marketing-company"
              >
                <p>Performance Marketing</p>
              </NavLink>

              <Typography color="text.primary">
                Search Engine Marketing
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-4 col-md-4 col-lg-4 performance-sem-subdiv">
              <div>
                <img
                  className="img-fluid "
                  src="assest/photos/performance-marketing/ads.png"
                  alt="G-Rank"
                  title="performance banner"
                />
              </div>
            </div>
            <div className="col-4 col-md-4 col-lg-4 performance-sem-subdiv">
              <div>
                <img
                  className="img-fluid yahoo-img"
                  src="assest/photos/performance-marketing/yehoo-img.png"
                  alt="G-Rank"
                  title="performance banner"
                />
              </div>
            </div>
            <div className="col-4 col-md-4 col-lg-4 performance-sem-subdiv">
              <div>
                <img
                  className="img-fluid "
                  src="assest/photos/performance-marketing/bind-ads-logo2.png"
                  alt="G-Rank"
                  title="performance banner"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6 performance-div2"
              id="order2"
            >
              <h4>No Empty Promises, Only Conversions with SEM</h4>
              <p>
                Why settle for the ordinary when you can experience
                extraordinary results? Search engine marketing, is a highly
                effective way to increase your ranking on search engines like
                Google, and attract qualified traffic to your website. At
                G-Rank, we understand your success depends on more than just
                traffic. That's why we go beyond mere clicks and focus on
                generating high-quality conversions that impact your bottom
                line. Our proven strategies and innovative approaches ensure
                that every visitor to your site is a potential customer, primed
                and ready to convert.
              </p>
              <p>
                ​ Our comprehensive SEM services include keyword research, ad
                creation, campaign optimization, and conversion tracking,
                ensuring that every aspect of your marketing strategy aligns
                seamlessly.
              </p>
            </div>
            <div
              className="col-12 col-md-6 col-lg-6 performance-sem-div2"
              id="order1"
            >
              <img
                className="img-fluid "
                src="assest/photos/performance-marketing/performance-sem-object.png"
                alt="G-Rank"
                title="performance banner"
              />
            </div>
          </div>
        </div>

        <div className="container-fluid ads-batch">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 ads-batch-div1">
              <h4>Our SEM Services</h4>
              <p>
                We provide a wide range of services related to
                <br /> SEM. Our services include:
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="row">
                <div className="col-4 col-md-4 col-lg-4 ads-batch-div2">
                  <img
                    className="img-fluid "
                    src="assest/photos/performance-marketing/ads-img-small.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                  <h4>Google Ads​</h4>
                </div>
                <div className="col-4 col-md-4 col-lg-4 ads-batch-div2">
                  <img
                    className="img-fluid "
                    src="assest/photos/performance-marketing/yehoo-img.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                  <h4>yahoo Ads​​</h4>
                </div>
                <div className="col-4 col-md-4 col-lg-4 ads-batch-div2">
                  <img
                    className="img-fluid "
                    src="assest/photos/performance-marketing/bing-imgs-small.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                  <h4>Bing Ads​</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="branding-process-div">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 ">
                <img
                  className="img-fluid branding-pro-img mobile-none"
                  src="assest/photos/branding/branding-consulting-process-img.png"
                  alt="G-Rank"
                  title="performance banner"
                />
                <img
                  className="img-fluid branding-pro-img desktop-none"
                  src="assest/photos/branding/automation.png"
                  alt="G-Rank"
                  title="performance banner"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6 branding-consulting-process-text">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Our
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Process
                </motion.h2>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  At G-Rank, we understand the importance of a well-executed
                  Search Engine Marketing (SEM) strategy in driving targeted
                  traffic and achieving business goals. Our SEM experts
                  collaborate closely with you to understand your business
                  objectives, target audience, and competitive landscape.
                  <br />
                  <br />
                  We conduct extensive keyword research to identify your
                  campaigns' most relevant and high-performing keywords. This
                  ensures that your ads reach the right audience at the right
                  time.​
                  <br />
                  <br />
                  We leverage advanced analytics tools to track key metrics,
                  identify trends, and make data-driven decisions to improve
                  your SEM campaigns continuously.​
                </motion.p>
              </div>
            </div>
          </div>
        </div>
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* what we do start */}
        <div className="container-fluid branding-sem-consulting-what">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 " id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-sem-consulting-what-heading"
              >
                <h4>What</h4>
                <h2>we Do</h2>
              </motion.div>
              <div className="branding-sem-consulting-what-text">
                <p>
                  Recognizing the potential of pay-per-click (PPC) advertising
                  for business growth
                </p>
                <p>
                  Employing experienced professionals who use advanced
                  strategies, meticulous research, and data-driven insights
                </p>
                <p>
                  Creating highly effective PPC campaigns tailored to individual
                  business goals
                </p>
                <p>
                  Offering comprehensive services including keyword research, ad
                  creation, campaign management, bid optimization, and
                  performance tracking
                </p>
                <p>
                  Staying up-to-date with industry trends and innovations to
                  maintain campaign effectiveness
                </p>
                <p>
                  Providing location-specific PPC services for targeted audience
                  reach (e.g., PPC services in Bangalore and Hyderabad)
                </p>
                <p>
                  Emphasizing transparency through detailed reporting and
                  continuous optimization
                </p>
                <p>
                  Consistently monitoring campaigns, using data-driven
                  adjustments to maximize ROI and competitiveness
                </p>
                <h2>Top ppc services agency in hyderabad and bangalore</h2>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5" id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-blue.png"
                alt="G-Rank"
                title="grank"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabIndex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SearchEngineMarketing;
