// UtmHandler.js
import React, { useEffect, useState } from "react";

const UtmHandler = ({ children }) => {
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    // Parse UTM parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const utmValues = {
      utm_source: urlParams.get("utm_source") || "",
      utm_medium: urlParams.get("utm_medium") || "",
      utm_campaign: urlParams.get("utm_campaign") || "",
      utm_term: urlParams.get("utm_term") || "",
      utm_content: urlParams.get("utm_content") || "",
    };

    // Set UTM parameters in state
    setUtmParams(utmValues);
  }, []);

  return children(utmParams);
};

export default UtmHandler;
