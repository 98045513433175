import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink, useNavigate } from "react-router-dom";
import Seo from "../seo/Seo";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomerContext from "../blog-folder/CustomerContext";

const PerformanceMarketing = () => {
  const { handleOpen } = React.useContext(CustomerContext);

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  //  useState section end----------------------------------------
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings7 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Slider section start-----------------------------------------
  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-next2" onClick={onClick}>
        <ArrowForwardIosIcon className="forword-arrow2" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-prev2" onClick={onClick}>
        <ArrowBackIosIcon className="backword-arrow2" />
      </div>
    );
  }

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };
    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/performance-marketing-company{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };
  return (
    <>
      <Seo
        title="Performance Marketing Agency in Bangalore | Hyderabad"
        description="Performance marketing agency in Bangalore and Hyderbad. Explore how we optimize campaigns, maximize ROI, and propel your brand to new heights organically."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="performance marketing company in bangalore ,
        performance marketing company in hyderbad,
        top performance marketing agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/performance-marketing-company"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        <div className="performance-banner">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/performance-marketing/performance-main-banner.png"
            alt="G-Rank"
            title="performance banner"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/performance-marketing/performance-main-banner-mobile.png"
            alt="G-Rank"
            title="performance banner"
          />
          <div className="performance-banner-div">
            {/* <h1 data-text="Performance Marketing Company"> */}
            <h1>
              Performance
              <br />
              Marketing Company
            </h1>
            <h2>
              Inspire <span className="ideas-red">Ideas</span> that{" "}
              <strong> Sell.</strong>
            </h2>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="text.primary">
                Performance Marketing
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-4 col-md-4 col-lg-4 performance-subdiv">
              <div>
                <h4>
                  Real-time <br />
                  Growth
                </h4>
                <p>
                  Our experts use data to track the effectiveness of campaign
                  and optimize in real-time
                </p>
              </div>
            </div>
            <div className="col-4 col-md-4 col-lg-4 performance-subdiv">
              <div>
                <h4>High return on Investment</h4>
                <p>
                  Our Strategy aims at getting higher return on minimal
                  investment
                </p>
              </div>
            </div>
            <div className="col-4 col-md-4 col-lg-4 performance-subdiv">
              <div>
                <h4>Boost your sales</h4>
                <p>
                  Our strategy aims to build a way to customer consciousness to
                  increase sales.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6 performance-div2"
              id="order2"
            >
              <h4>Supercharge Your Marketing Campaigns</h4>
              <p>
                Effective marketing plays a crucial role in driving growth and
                maximizing ROI. One effective approach to achieving outstanding
                results is through performance marketing.
              </p>
              <p>
                At, G-Rank, we transform marketing into a results-driven
                powerhouse. Our performance marketing strategies are designed to
                skyrocket your business to new heights. Our multi-channel
                approach ensures maximum visibility, targeting the right
                audience at the right time.
              </p>
            </div>
            <div
              className="col-12 col-md-6 col-lg-6 performance-div2-img"
              id="order1"
            >
              <img
                className="img-fluid w-100"
                src="assest/photos/performance-marketing/marketingboostsales.png"
                alt="G-Rank"
                title="performance banner"
              />
            </div>
          </div>
        </div>
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        <div className="container-fluid performance-div3">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-5 performance-div3-sec1">
              <h2>Why Choose</h2>
              <img
                className="img-fluid performance-div3-sec1-img"
                src="assest/photos/performance-marketing/performance-grank-logo.png"
                alt="G-Rank"
                title="performance banner"
              />
              <img
                className="img-fluid"
                src="assest/photos/performance-marketing/manwithcloud.png"
                alt="G-Rank"
                title="performance banner"
              />
            </div>
            <div className="col-12 col-md-12 col-lg-7 performance-div3-sec2">
              <div className="row">
                <div className="col-md-12">
                  <div className=" h-max position-relative">
                    <div className="rhombus69 rho1">
                      <div className="rotate">
                        <h3>Exceptional Result</h3>
                        <p>
                          Our strategies are tried and tested, providing
                          visibility and improved conversions for your business.
                        </p>
                      </div>
                    </div>

                    <div className="rhombus69 rho2">
                      <div className="rotate">
                        <h3>Tailored Solution</h3>
                        <p>
                          We understand that every business is unique so we
                          tailor our performance marketing solutions to your
                          specific goals and requirements.
                        </p>
                      </div>
                    </div>

                    <div className="rhombus69 rho3">
                      <div className="rotate">
                        <h3> Transparency</h3>
                        <p>
                          We prioritize complete transparency and
                          accountability, providing regular reports and updates
                          on campaign progress and performance.
                        </p>
                      </div>
                    </div>

                    <div className="rhombus69 rho4">
                      <div className="rotate">
                        <h3> Collaborative Nature</h3>
                        <p>
                          Our team of experts collaborates closely with you to
                          develop a personalized strategy that maximizes your
                          ROI.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="h-max desktop-none">
                    <div className="performance-why-cho-div">
                      <div>
                        <h3>Exceptional Result</h3>
                        <p>
                          Our strategies are tried and tested, providing
                          visibility and improved conversions for your business.
                        </p>
                      </div>
                    </div>

                    <div className="performance-why-cho-div">
                      <div>
                        <h3>Tailored Solution</h3>
                        <p>
                          We understand that every business is unique so we
                          tailor our performance marketing solutions to your
                          specific goals and requirements.
                        </p>
                      </div>
                    </div>

                    <div className="performance-why-cho-div">
                      <div>
                        <h3> Transparency</h3>
                        <p>
                          We prioritize complete transparency and
                          accountability, providing regular reports and updates
                          on campaign progress and performance.
                        </p>
                      </div>
                    </div>

                    <div className="performance-why-cho-div">
                      <div>
                        <h3> Collaborative Nature</h3>
                        <p>
                          Our team of experts collaborates closely with you to
                          develop a personalized strategy that maximizes your
                          ROI.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="slider-div77-text">
            <h4>Our Performance Marketing Services</h4>
            <h2>
              Best performance marketing agency in Hyderabad and Bangalore
            </h2>
            <p>
              We provide a wide range of services related to performance
              marketing. Our <br />
              services include:
            </p>
          </div>
          <Slider {...settings7}>
            <div className="slider-div77">
              <div className="slider-div7">
                <div>
                  <img
                    className="img-fluid"
                    src="assest/photos/performance-marketing/ad4.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                </div>
                <h4>Display Ads</h4>
              </div>
            </div>
            <div className="slider-div77">
              <div className="slider-div7">
                <div>
                  <img
                    className="img-fluid"
                    src="assest/photos/performance-marketing/ad2.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                </div>
                <h4>Search Ads</h4>
              </div>
            </div>
            <div className="slider-div77">
              <div className="slider-div7">
                <div>
                  <img
                    className="img-fluid"
                    src="assest/photos/performance-marketing/ad3.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                </div>
                <h4>Social Media Marketing</h4>
              </div>
            </div>

            <div className="slider-div77">
              <div className="slider-div7">
                <div>
                  <img
                    className="img-fluid"
                    src="assest/photos/performance-marketing/ad5.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                </div>
                <h4>Search Engine Marketing</h4>
              </div>
            </div>
            <div className="slider-div77">
              <div className="slider-div7">
                <div>
                  <img
                    className="img-fluid"
                    src="assest/photos/performance-marketing/ad6.png"
                    alt="G-Rank"
                    title="performance banner"
                  />
                </div>
                <h4>Discovery Ads</h4>
              </div>
            </div>
          </Slider>
        </div>
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQs & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          What is performance marketing?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Performance Marketing is a comprehensive approach that
                          focuses on measurable results, leveraging various
                          channels to drive specific actions such as clicks,
                          leads, or sales. choices.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Is performance marketing suitable for all businesses?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Performance marketing can benefit various types of
                          businesses, but its suitability depends on factors
                          such as business goals, target audience, and available
                          resources.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          How does Search Engine Marketing benefit my business?
                          <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          SEM can enhance your online presence, attract targeted
                          traffic, and increase conversions by placing your
                          business at the forefront of search engine results for
                          relevant queries. This can be done through Google ads,
                          Bing ads, YouTube ads and social media ads.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          Which Social media platforms are best for marketing my
                          business?
                          <span>
                            {open4 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          The choice of platforms depends on your target
                          audience. Generally, popular options include Facebook,
                          Instagram, Twitter, and LinkedIn, but the most
                          effective platform varies based on your business goals
                          and audience demographics.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabIndex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default PerformanceMarketing;
