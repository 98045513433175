import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Seo from "../seo/Seo";
import { loadProducts } from "../../../features/frontend/addtocart/thunk-product";
import { useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import CustomerContext from "../blog-folder/CustomerContext";

const Domain = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings12 = {
    className: "center-slider2",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    autoplay: true,
    speed: 2000,

    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // FAQs section start
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  //  useState section end----------------------------------------
  // FAQs section end

  // Define your Schema.org data
  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Why do I need a website for my business?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "A website helps promote and sell products/services, connect with new and existing customers, build credibility, compete with larger businesses, and maintain control over branding and marketing",
        },
      },
      {
        "@type": "Question",
        name: "Why should I get a domain name from G-Rank?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "G-Rank is a trusted domain registrar, offering easy domain search and domain name generator tools to find the perfect website address for your business.",
        },
      },
      {
        "@type": "Question",
        name: "Why use G-Rank Website Builder?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "G-Rank Website Builder is a fast and simple way to create a website with professionally designed templates. Just add your content, and you're ready to launch.",
        },
      },
      {
        "@type": "Question",
        name: "How does G-Rank help small business owners succeed?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "G-Rank offers an all-in-one solution for creating an effective online presence. It provides website-building tools, hosting plans, professional email, and online marketing tools. G-Rank also offers personalized support from G-Rank Guides.",
        },
      },
      {
        "@type": "Question",
        name: "Why do I need a professional email?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Switching to a domain-based email, like tommy@vicecity.com, increases customer trust, provides better security with spam and virus filters, and promotes your business more effectively by including your web address in every email you send.",
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title="Buy Domain Name | Bangalore | Hyderabad "
        description="Buy domain name and claim your online identity today. Explore our Domain section to find the perfect web address for your business or personal project."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="buy domain name,
        buy website domain,
        domain name purchase,
        buy premium domains,
        buy domains cheap,
        buy domain online,
        top domain service provider in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/domain"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/domain/domain-banner2.png"
            alt="G-Rank"
            title="domain"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/domain/domain-banner2-mobile.png"
            alt="G-Rank"
            title="domain"
          />

          <div className="domain-banner-div">
            <motion.h4
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Level up your
            </motion.h4>
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Domain Security
              {/* Domain */}
            </motion.h1>
            <motion.h5
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              With our Two-step Authentication process.
            </motion.h5>

            <motion.h5
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Secure your Perfect Domain
            </motion.h5>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#fff">Domain</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* second-section start */}
        <section className="domain-second-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 domain-second-heading">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Why buy a Domain with G- Rank?
                </motion.h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="domain-second-div">
                  <div>
                    <img
                      className="img-fluid"
                      src="assest/photos/domain/domain-icon6.png"
                      alt="G-Rank"
                      title="domain"
                    />
                  </div>
                  <div>
                    <h3>Easy Navigation </h3>
                    <h4>
                      Customers can effortlessly purchase website domains and
                      establish their unique online identity without
                      overspending.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="domain-second-div">
                  <div>
                    <img
                      className="img-fluid"
                      src="assest/photos/domain/domain-icon5.png"
                      alt="G-Rank"
                      title="domain"
                    />
                  </div>
                  <div>
                    <h3>User-Friendly interface </h3>
                    <h4>
                      Our user-friendly interface allows for convenient online
                      domain purchases, simplifying the process for users.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="domain-second-div">
                  <div>
                    <img
                      className="img-fluid"
                      src="assest/photos/domain/domain-icon4.png"
                      alt="G-Rank"
                      title="domain"
                    />
                  </div>
                  <div>
                    <h3>Cost effective</h3>
                    <h4>
                      Our mission is to redefine affordability, offering premium
                      domains at unbelievably low prices, empowering everyone to
                      establish their online presence and thrive.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  second-section end */}
        {/* slider section start*/}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="domain-slider-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Features
              </motion.h4>
            </div>
            <div>
              <Slider {...settings12}>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Brand Integrity</h4>
                    <p>
                      Preventing misuse or imitation
                      <br /> of your brand's domain.
                    </p>
                  </div>
                </div>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Cybersquatting Prevention</h4>
                    <p>
                      Avoiding unauthorized Domain <br />
                      registrations for profit.
                    </p>
                  </div>
                </div>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Phishing Defense</h4>
                    <p>
                      Protecting against fake sites
                      <br /> stealing user data.
                    </p>
                  </div>
                </div>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Legal Compliance</h4>
                    <p>
                      Adhering to trademark <br />
                      laws and regulations.
                    </p>
                  </div>
                </div>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Trust Building</h4>
                    <p>
                      Ensuring a secure and <br />
                      consistent online presence.
                    </p>
                  </div>
                </div>
                <div className="domain-slider-div2">
                  <div className="domain-slider-div">
                    <h4>Search Ranking</h4>
                    <p>
                      Maintaining SEO and avoiding
                      <br /> confusion from unauthorized
                      <br />
                      domains with similar content.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* slider section end */}
        {/* FAQs section start*/}
        <section className="domain-extention-FAQs-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="domain-slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  FAQ's
                </motion.h4>
              </div>
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          How do I register a domain with G-Rank?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Registering a domain with G-Rank is simple. Just fill
                          an enquiry form and enter your desired domain name in
                          the message field, and then we will check its
                          availability, and follow the easy steps to complete
                          the registration process for you.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Can I transfer an existing domain to G-Rank?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Yes, you can easily transfer your existing domain to
                          G-Rank. Just fill an enquiry form and enter your
                          desired domain name in the message field. Our support
                          team will call you and guide you through the transfer
                          process, ensuring a seamless transition for your
                          website.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          How do I buy a domain name?
                          <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Anyone can buy a domain name. The most common way to
                          do so is to visit a domain name registrar, such as G
                          Rank, GoDaddy, Google Domains, or Namecheap, key in
                          the domain you want to buy, and pay a fee. Domain
                          Names make it easier for us to navigate the online
                          world and avoid memorizing long IP addresses.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          Can I protect my personal information with G-Rank's
                          domain privacy feature?
                          <span>
                            {open4 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G Rank offers domain privacy protection to shield your
                          personal information from public view. Your privacy is
                          our priority, and this feature is included with domain
                          registration.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/domain/white-and-blue-back.png"
              alt="G-Rank"
              title="domain"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Want to Elevate Your Brand?
                <br /> Get in Touch.
              </motion.p>
              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default Domain;
