import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Seo from "../seo/Seo";
import AddToCartSecu from "../../../features/frontend/addtocartsecu/AddToCartSecur";
import { loadProducts } from "../../../features/frontend/addtocartsecu/thunk-productsecu";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
const StandardSSL = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, []);
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };
    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };

  // ----------------

  // Define your Schema.org data
  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Why do I need an SSL certificate from G-Rank?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "An SSL certificate from G-Rank ensures that the data exchanged between your website's server and visitors' browsers is encrypted and secure. This helps protect sensitive information, such as login credentials and credit card details, from potential hackers.",
        },
      },
      {
        "@type": "Question",
        name: "Can I use a standard SSL certificate for multiple subdomains?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "It depends on the type of SSL certificate you purchase. Some standard certificates only cover a single domain, while others can include multiple subdomains (wildcard certificates) or multiple domains (multi-domain certificates).",
        },
      },
      {
        "@type": "Question",
        name: "Is an SSL certificate necessary for my website?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, having an SSL certificate is highly recommended for all websites. Search engines like Google prioritize secure websites in search results, and visitors are more likely to trust and stay on a website that displays the padlock icon in the address bar, indicating a secure connection.",
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title="SSL Certificate Provider in Bangalore | Hyderabad"
        description="SSL Certificate provider in bangalore and hyderabad. Explore how we encrypt data, build trust, and protect your online visitors' information. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="top standard ssl certificate provider in india,
        buy standard ssl certificate"
        searchengines="ALL"
        canonical="https://www.grank.co.in/standard-ssl"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/security/security-banner2.png"
            alt="G-Rank"
            title="security"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/security/security-banner2-mobile.png"
            alt="G-Rank"
            title="security"
          />

          <div className="security-banner-div">
            <h1 className="waviy-sec ">
              <span>
                Standard
                <br />
                SSL Provider
              </span>
            </h1>
            <h2>It's all about being authentic.</h2>
            <h3 onClick={handleOpen}>
              <NavLink to="">Find Out More</NavLink>
            </h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/web-security-services"
              >
                <p>Security</p>
              </NavLink>

              <Typography color="#000">Standard SSL</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* blue text start */}
        <section className="">
          <div className="container">
            <div className="row ">
              <div className="col-12 security-standard-blue-div">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  SSL Certification In Four Easy Steps
                </motion.h4>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  After you purchase an SSL certificate, it needs to be
                  activated. After activation,
                  <br /> you'll receive installation instructions. You can
                  manage your certificate in the
                  <br /> Namecheap Account Panel from that point.
                </motion.p>
              </div>
            </div>
          </div>
        </section>
        {/* blue text end  */}
        {/* step section start  */}
        <section className="standard-step-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 col-md-6 col-lg-6 standard-step-p"
                id="order2"
              >
                <h4 className="standard-step1">
                  <p className="desktop-none">step4</p>
                  You may manage your SSL certificates (including renewal and
                  <br />
                  re-issuance) in your Account Panel.
                </h4>
                <h4 className="standard-step2">
                  <p className="desktop-none">step3</p>
                  You will receive instructions on installing your SSL
                  certificate once it has been validated and issued. Click here
                  for more information on the installation process.
                </h4>
                <h4 className="standard-step3">
                  <p className="desktop-none">step2</p>
                  You can activate your SSL certificate from the Account Panel
                  immediately after purchase (or later if you choose.) CSR code
                  assignment and domain name indication will occur during the
                  activation process.
                </h4>
                <h4 className="standard-step4">
                  <p className="desktop-none">step1</p>
                  Begin by purchasing the right SSL certificate for your needs.
                  Choose from the three types listed above.
                </h4>
              </div>
              <div
                className="col-12 col-md-6 col-lg-6 standard-step-div"
                id="order1"
              >
                <div className="standard-step-img">
                  <img
                    className="img-fluid "
                    src="assest/photos/security/step-img.png"
                    alt="G-Rank"
                    title="security"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* step section end  */}
        {/* table start */}
        {/* <section className="standardssl-section"> */}
        <section className="">
          <div className="container security-table-section">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="security-circle-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                  // style={{ color: "#fff" }}
                >
                  Choose the right SSL Certificate
                </motion.h4>
              </div>
            </div>
          </div>
          {/* <AddToCartSecu /> */}
          <section className="security-padding">
            <div className="">
              <div className="overflow-div">
                {/* <div className="">
                  <div className="">
                    <div className=" table-static-div">
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p style={{ color: " #436fe8" }}>
                            SSL Full Features Comparison
                          </p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Protects</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Validation Type</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Encryption Type</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Benefits</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>SSL Issue Period</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>HTTPS & Padlock</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Security Trust Seal</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Green Address Bar</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Supports</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Free Relssues</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Technical Support</p>
                        </div>
                      </h4>
                    </div>
                  </div>
                </div> */}

                <AddToCartSecu />
              </div>
            </div>
          </section>
          {/* <AddToCartSecu /> */}
        </section>
        {/* table end  */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="security-circle-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  FAQ's
                </motion.h4>
              </div>
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          Why do I need an SSL certificate from G-Rank?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          An SSL certificate from G-Rank ensures that the data
                          exchanged between your website's server and visitors'
                          browsers is encrypted and secure. This helps protect
                          sensitive information, such as login credentials and
                          credit card details, from potential hackers.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Can I use a standard SSL certificate for multiple
                          subdomains?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          It depends on the type of SSL certificate you
                          purchase. Some standard certificates only cover a
                          single domain, while others can include multiple
                          subdomains (wildcard certificates) or multiple domains
                          (multi-domain certificates).
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse "
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          Is an SSL certificate necessary for my website?
                          <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse show"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Yes, having an SSL certificate is highly recommended
                          for all websites. Search engines like Google
                          prioritize secure websites in search results, and
                          visitors are more likely to trust and stay on a
                          website that displays the padlock icon in the address
                          bar, indicating a secure connection.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/*Input start */}
        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*Input start */}
      </section>
    </>
  );
};

export default StandardSSL;
