import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Header from "../header2/Header";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();
  const navi = () => {
    navigate("/");
  };
  const [userMessage, setUserMessage] = useState("");

  useEffect(() => {
    // Retrieve the message from localStorage or global state
    const storedMessage = localStorage.getItem("userMessage");
    setUserMessage(storedMessage || "");
  }, []);
  return (
    <>
      <section className="section-top">
        <Header />
        <div className="container-fluid thankyou-div">
          <h1>Thank You! {userMessage} </h1>
          <h2>
            Thank you for your interest! Check your email for a link to the
            guide
          </h2>
          <Button variant="contained" color="primary" onClick={navi}>
            Back to Home
          </Button>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
