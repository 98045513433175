import React, { useRef, useState } from "react";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

const Automotive = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    to: "suraj@grank.co.in",
    subject: "Enquire From Career Form",
    message: "",
    name: "",
    phone: "",
    branch: "",
    designation: "",
    experience: "",
    city: "",
    business: "",
    company: "",
    cc: "server@grank.co.in",
    bcc: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(
          "https://servers.myvidya.co.in/send-email2",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const result = await response.json();
          localStorage.setItem("userMessage", formData.email);

          navigate("/thank-you");
        } else {
          const errorResult = await response.json();
        }
      } catch (error) {}
      // Form is valid, you can submit the data or perform other actions here
    } else {
      // Form has errors, update the state to display error messages
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    // Add validation rules here
    if (!data.name || !data.name.trim()) {
      errors.name = "Name is required";
    }

    if (!data.phone || !data.phone.trim()) {
      errors.phone = "Phone is required";
    }

    if (!data.email || !data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email.trim())) {
      errors.email = "Invalid email format";
    }

    if (!data.message || !data.message.trim()) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Grank",
    url: "https://www.grank.co.in/career",
    logo: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8522953903",
      contactType: "",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://twitter.com/Grank_India/",
      "https://www.instagram.com/grank.india/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
    ],
  };

  return (
    <>
      <Seo
        title="Career | G-Rank"
        description="Explore our Career webpage to discover exciting opportunities, a supportive work environment, and a chance to be part of a dynamic team. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/career"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 career-img-banner "
            src="assest/photos/career/career-banner.png"
            alt="G-Rank"
            title="cureer"
          />

          <div className="career-banner-div">
            {/* <h2>Hello there!</h2> */}
            <h2 className="waviy ">
              <span style={{ color: "#fff" }}>Hello there!</span>
            </h2>
            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.2 }}
            >
              If you have it in you to be a change-maker, team player and
              impactful contributor, you are welcome.
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{
                paddingTop: "40px",
                display: "flex",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#fff">Blog</Typography>
            </Breadcrumbs>
          </div>

          <div className="career-yellow-div">
            <h2>What we look for in you</h2>
          </div>
        </div>
        {/* banner end */}
        {/* second-section start */}
        <section className="career-second-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon1.png"
                  alt="G-Rank"
                  title="cureer"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h4>Accountablity</h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid "
                  src="assest/photos/career/career-icon2.png"
                  alt="G-Rank"
                  title="cureer"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                />
                <h4>Curiosity</h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon3.png"
                  alt="G-Rank"
                  title="cureer"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.8 }}
                />
                <h4>Proactiveness</h4>
              </div>
            </div>
          </div>
        </section>
        {/* second-section end */}
        {/* career at g-rank start */}
        <section className=" career-at-g-rank-section">
          <div className="container">
            <div className="row">
              <motion.div
                className="col-12 career-at-g-rank-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <h4>Careers at G-Rank</h4>
                <h2>
                  We want to make agency life pleasant, productive and
                  empowering
                </h2>
              </motion.div>
              <motion.div
                className="col-12 col-md-4 col-lg-4 career-at-g-rank-div"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <div>
                  <h4>
                    HR Intern
                    <br /> Consultant Crp. Sales(3)
                  </h4>
                  <h2>Client Success Manager</h2>
                </div>
              </motion.div>
              <div className="col-12 col-md-4 col-lg-4 career-at-g-rank-div">
                <div>
                  <h4>
                    Sr. Adwords Specialist
                    <br />
                    Adword Analyst
                  </h4>
                  <h2>
                    Graphic designer Intern(2)
                    <br />
                    Content Intern(2)
                  </h2>
                </div>
              </div>
              <motion.div
                className="col-12 col-md-4 col-lg-4 career-at-g-rank-div"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <div>
                  <h4>SEO Intern(2)</h4>
                  <h2>Adwords Intern(2)</h2>
                </div>
              </motion.div>
              <div className="col-12 col-md-4 col-lg-4 career-at-g-rank-div">
                <div>
                  <h4>Sr Motion Graphics</h4>
                  <h2>2 - 2-3 Yrs of Experience</h2>
                </div>
              </div>
              <motion.div
                className="col-12 col-md-4 col-lg-4 career-at-g-rank-div"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <div>
                  <h4>Sr.Trainer (Digital Marketing )</h4>
                  <h2>2 - 5 Yrs of Experience</h2>
                </div>
              </motion.div>
              <div className="col-12 col-md-4 col-lg-4 career-at-g-rank-div">
                <div>
                  <h4>Sr. Sales Executive</h4>
                  <h2>2-1-2 Yrs of Experience</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  career at g-rank end */}
        {/* career form start*/}
        <section className="contact-us-section">
          <div className="container contact-us-contain">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-7 align-self-center">
                <img
                  className="img-fluid w-100 contact-img"
                  src="assest/photos/contact-us/contact-us2.png"
                  alt="G-Rank"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-5 ">
                <div className="contact-us-form">
                  <h1 style={{ color: "#436fe8" }}>Career Form</h1>
                  <p></p>
                  <form onSubmit={handleSubmit}>
                    <div className="contact-flex">
                      <input
                        placeholder="Name"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                      {errors.name && (
                        <span className="error">{errors.name}</span>
                      )}
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                      />
                      {errors.phone && (
                        <span className="error">{errors.phone}</span>
                      )}
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>

                    <div className="contact-flex">
                      {/* <h3>
                        <label>Designations</label>
                      </h3> */}

                      <select
                        name="designation"
                        className="contact-select-div"
                        onChange={handleChange}
                      >
                        <option value="none">Designation</option>
                        <option value="HTML Developer">HTML Developer</option>
                        <option value="PHP/Wordpress Developer">
                          PHP/Wordpress Developer
                        </option>
                        <option value="MERN Stack Developer">
                          MERN Stack Developer
                        </option>
                        <option value="SEO Analyst / manager">
                          SEO Analyst / manager
                        </option>
                        <option value="Degital Marketing Analyst">
                          Degital Marketing Analyst
                        </option>
                        <option value="Graphic Designer">
                          Graphic Designer
                        </option>
                        <option value="Motion Graphic Designer">
                          Motion Graphic Designer
                        </option>
                        <option value="Content Writer">Content Writer</option>
                        <option value="Customer Success Manager">
                          Customer Success Manager
                        </option>
                        <option value="Sale Manager B2B / B2C">
                          Sale Manager B2B / B2C
                        </option>
                        <option value="HR Recruiter / Trainer">
                          HR Recruiter / Trainer
                        </option>
                        <option value="HR Executive / Manager">
                          HR Executive / Manager
                        </option>
                      </select>
                    </div>
                    <div className="contact-flex">
                      <select
                        name="experience"
                        className="contact-select-div"
                        onChange={handleChange}
                      >
                        <option value="none">Experience</option>
                        <option value="Fresher">Fresher</option>
                        <option value="0 years">0 years</option>
                        <option value="1 years">1 years</option>
                        <option value="2 years">2 years</option>
                        <option value="3 years">3 years</option>
                        <option value="4 years">4 years</option>
                        <option value="5 years">5 years</option>
                        <option value="more than 5 years">
                          more than 5 years
                        </option>
                      </select>
                    </div>
                    <div className="contact-flex">
                      <select
                        name="branch"
                        className="contact-select-div"
                        onChange={handleChange}
                      >
                        <option value="none">Branch</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Both">Both</option>
                      </select>
                    </div>
                    <div className="contact-flex">
                      <input placeholder="City" type="text" name="user_city" />
                    </div>

                    <div className="contact-flex">
                      <textarea
                        placeholder="Message"
                        name="message"
                        id="myInput"
                        onChange={handleChange}
                        value={formData.message}
                      />
                      {errors.message && (
                        <span className="error">{errors.message}</span>
                      )}
                    </div>

                    <h4>
                      <button type="submit">send</button>
                    </h4>
                  </form>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    1-8-382, Ground Floor, Queen’s Plaza,
                    <br /> Begumpet,Hyderabad – 500003
                  </p>
                  <h1>+91 8121826992</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    2nd Floor, R K Lake Tower, Service Road, Subramani Nagar,
                    <br />
                    Guddadahalli, Hebbal, Bengaluru, Karnataka-560024
                  </p>
                  <h1>+91 9686442800</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* career form end */}

        {/*  dont see start */}
        <section className="career-dont-see-section">
          <div className="container">
            <div className="row">
              <div className="col-12 career-dont-see-div">
                <h4>Don't see a suitable position?</h4>
                <h2>
                  We are for everyone. Write to us at{" "}
                  <span> careers@grank.co.in</span>
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/*  dont see end */}
      </section>
    </>
  );
};

export default Automotive;
