import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

const FullLayout = () => {
  return (
    <>
      <Sidebar />
    </>
  );
};

export default FullLayout;
