import React, { useState, useEffect, Suspense, lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import BlankLayout from "./layout/blanklayout/BlankLayout";
import FullLayout from "./layout/full-layout/FullLayout";

// ----

function App() {
  const [users, setUsers] = useState([]);
  // axios.defaults.withCredentials = true;
  const helmetContext = {};

  const ProtectedRoute = ({ children }) => {
    const loggedUser = users.find((user) => user.role == "admin");
    console.log("loggedUser-div:", loggedUser);
    return loggedUser ? children : <Navigate to="/login" />;
  };

  useEffect(() => {
    axios
      .get("https://servers.myvidya.co.in/api/v1/users")
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <HelmetProvider context={helmetContext}>
        {/* <Suspense fallback={<div>loading</div>}> */}
        <Routes>
          <Route path="/*" element={<BlankLayout />} />
          <Route
            path="/secured/*"
            element={
              <ProtectedRoute>
                <FullLayout />
              </ProtectedRoute>
            }
          />
        </Routes>
        {/* </Suspense> */}
      </HelmetProvider>
    </>
  );
}

export default App;
