import React from "react";
import Login from "../../features/frontend/authentication/Login";
import Register from "../../features/frontend/authentication/Register";
export default [
  {
    label: "SignIn",
    path: "/login",
    showInMenu: true,
    addRoute: true,
    component: <Login />,
    authenticated: "no",
  },

  {
    label: "Profile",
    path: "/secured/customers",
    showInMenu: true,
    addRoute: false,
    component: <Register />,
    authenticated: "yes",
  },
];
